import $ = require("jquery");

import "normalize.css/normalize.css";
import "~/Src/SharedStyles/fonts.scss";
import "~/Src/SharedStyles/defaults.scss";
import "~/Src/SharedStyles/utilities.scss";
import "~/Src/SharedStyles/buttons.scss";
import "~/Src/SharedStyles/icons.scss";
import "~/Src/SharedStyles/forms.scss";
import "~/Src/SharedStyles/grid.scss";
import "~/Src/Components/Notification/Notification.scss";
import "./Default.scss";

$(document).on("keydown", 'a[role="button"]', e => {
    if (e.which === 32) {
        $(e.currentTarget).trigger("click");
    }
});